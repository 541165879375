import {onFocus, onInput} from '../onfocus'
import {observe} from 'selector-observer'
import {on} from 'delegated-events'

function searchType(types: {[key: string]: number}): string {
  const params = new URLSearchParams(window.location.search)
  let type = params.get('type') || 'Repositories'
  let max = 0
  for (const key in types) {
    if (types[key] > max) {
      max = types[key]
      type = key
    }
  }
  return type
}

function termToken({prefix, value}: {prefix: string; value: string}): string {
  if (prefix === '') {
    return ''
  }
  if (value) {
    return `${prefix}${value}`
  }
  return ''
}

function buildQuery() {
  const inputs = Array.from(document.querySelectorAll<HTMLInputElement>('input[type=text].js-advanced-search-prefix'))
  const selects = Array.from(document.querySelectorAll<HTMLSelectElement>('select.js-advanced-search-prefix'))
  const checkboxes = Array.from(document.querySelectorAll<HTMLInputElement>('.js-advanced-search-prefix:checked'))

  const searchTerms = [...terms(inputs), ...terms(selects), ...terms(checkboxes)]

  const types = searchTerms.reduce(
    (acc, term) => {
      if (term.value && term.type) {
        acc[term.type]++
      }
      return acc
    },
    {Repositories: 0, Users: 0, Code: 0, Issues: 0} as Record<string, number>
  )

  const tokens = searchTerms.reduce((acc, item) => `${acc} ${termToken(item)}`.trim(), '')

  const keywords = document.querySelector<HTMLInputElement>('.js-advanced-search-input')!.value

  document.querySelector<HTMLInputElement>('.js-type-value')!.value = searchType(types)
  document.querySelector<HTMLInputElement>('.js-search-query')!.value = `${keywords} ${tokens}`.trim()

  const text = document.querySelector<HTMLElement>('.js-advanced-query')!
  text.innerHTML = ''
  text.textContent = tokens

  const span = document.createElement('span')
  span.textContent = keywords.trim()
  text.prepend(span, ' ')
}

function wrap(value: string): string {
  if (value.search(/\s/g) !== -1) {
    return `"${value}"`
  }
  return value
}

interface Term {
  prefix: string
  value: string
  type: string | null
}

function terms(inputs: Array<HTMLInputElement | HTMLSelectElement>): Term[] {
  return inputs
    .map(input => {
      const value = input.value.trim()
      const prefix = input.getAttribute('data-search-prefix')!
      const type = input.getAttribute('data-search-type')

      if (prefix === '') {
        return {prefix, value, type}
      } else if (value.search(/,/g) !== -1 && prefix !== 'location') {
        return value.split(/,/).map(key => ({prefix, value: wrap(key.trim()), type}))
      } else {
        return {prefix, value: wrap(value), type}
      }
    })
    .flatMap(x => x)
}

onInput('.js-advanced-search-prefix', function () {
  buildQuery()
})

on('change', '.js-advanced-search-prefix', buildQuery)

onFocus('.js-advanced-search-input', function (el) {
  const label = el.closest<HTMLElement>('.js-advanced-search-label')!
  label.classList.add('focus')

  el.addEventListener('blur', () => label.classList.remove('focus'), {once: true})
})

observe('.js-advanced-search-input', function () {
  buildQuery()
})
